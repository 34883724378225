<template>
  <div>
    <ViewTemplateReportPreview :panel="panel" :templateList="templateList" :templateReport="templateReport" />
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";

export default {
  name: "ReportPatrimonyView",
  components: {
    ViewTemplateReportPreview,
  },
  data() {
    return {
      panel: {
        module: "Estoque",
        title: "Patrimônio",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/HistoryPatrimonyOData",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "barCode",
            title: "Patrimônio",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "registeredDate",
            title: "Data",
            type: "dateTime",
            orderBy: "desc",
            requiredOData: false,
            selected: true,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "fullName",
            title: "Produto",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "statusMovimentName",
            title: "Status",
            type: "text",
            classCssTitle: "text-center",
            requiredOData: false,
            selected: true,
            classCssBody: "text-center",
          },
          {
            field: "numberRent",
            title: "Nº Locacão",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: true,
            requiredOData: false,
          },
          {
            field: "customerName",
            title: "Cliente",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
        ],
        rules: [
          {
            name: "registeredDate",
            title: "Período",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
            },
            notDuplicate: true,
            isRequired: true,
          },
          {
            name: "barCode",
            title: "Patrimônio",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "fullName",
            title: "Produto",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "statusMoviment",
            title: "Status",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Saída", value: 1 },
              { title: "Retorno", value: 2 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "numberRent",
            title: "Nº Locação",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "customerId",
            title: "Cliente",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/crm/customer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
        ],
      },
    };
  },
};
</script>
